import React, { useState } from "react";
import "./navbar_test.css";
import Lumen from "./lumenlogo.png";
import { NavLink } from "react-router-dom";

function navbar_test() {
  return (
    <div className="navbar_test">
      <div className="navbar_container">
        <div className="navbar_contents">
          <a href="https://lumenmyanmar.com">
            <img src={Lumen} alt="Logo" />
          </a>

          <nav class="nav-desktop">
            <NavLink className="options" to="/ ">
              About
            </NavLink>
            <NavLink className="options" to="/research">
              Research
            </NavLink>
            <NavLink className="options" to="/contact">
              Contact
            </NavLink>
          </nav>

          <input class="side-menu" type="checkbox" id="side-menu" />
          <label class="hamb" for="side-menu">
            <span class="hamb-line" />
          </label>
          <nav class="nav">
            {/* <div class="menu"> */}
            <NavLink className="nav-options" to="/ ">
              About
            </NavLink>
            <NavLink className="nav-options" to="/research">
              Research
            </NavLink>
            <NavLink className="nav-options" to="/contact">
              Contact
            </NavLink>
            {/* </div> */}
          </nav>
        </div>
      </div>
    </div>
  );
}

export default navbar_test;
