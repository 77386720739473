import React from "react";
import Footer from "../../Footer";
import "../Article.css";
import MakerDAO_Endgame from "./MakerDAO_Endgame.jpeg";
import CrvUsd_LLAMMA from "./Curve_crvusd_LLAMMA.jpeg";
import CrvUsd_LLAMMA2 from "./Curve_crvusd_LLAMMA2.jpeg";
import CrvUsd_LLAMMA3 from "./Curve_crvusd_LLAMMA3.jpeg";
import CrvUsd_LLAMMA4 from "./Curve_crvusd_LLAMMA4.jpeg";

function Article() {
  return (
    <div className="article">
      <div className="article_wrapper">
        <div className="article_container">
          <span className="article_header">MakerDAO: The Endgame Plan</span>
          <span className="article_date">Jun 2023 / Tun. L</span>
          <img
            className="article_img"
            src={MakerDAO_Endgame}
            alt="MakerDAO_Endgame"
          />
          <span className="article_title">Coming Soon...</span>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Article;
