import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="footer_container">
        <div className="footer_socials">
          <a href="https://lumenmyanmar.com">
            <span>Twitter</span>
          </a>
          <a href="https://lumenmyanmar.com">
            <span>Facebook</span>
          </a>
          <a href="https://lumenmyanmar.com">
            <span>LinkedIn</span>
          </a>
        </div>
        <div className="footer_copyright">
          <span>&copy; LumenResearch 2023. All rights reserved.</span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
