import React, { useState } from "react";
import "./Research.css";
import Footer from "./Footer";
import CurveV1 from "./images/CurveV1.jpeg";
import CurveV2 from "./images/CurveV2.jpeg";
import CurveDAO from "./images/CurveDAO.jpeg";
import CrvUsd from "./images/Curve_crvusd.png";
import CurveWars from "./images/CurveWars.jpeg";
import FraxV1 from "./images/FraxV1.jpeg";
import FraxV2 from "./images/FraxV2.jpeg";
import Frax_frxEth from "./images/Frax_frxEth.jpeg";
import Frax_FPI from "./images/Frax_FPI.jpeg";
import AaveV1 from "./images/AaveV1.jpeg";
import AaveV2 from "./images/AaveV2.jpeg";
import AaveV3 from "./images/AaveV3.jpeg";
import AaveGho from "./images/Aave_gho.png";
import UniswapV1 from "./images/UniswapV1.jpeg";
import UniswapV2 from "./images/UniswapV2.jpeg";
import UniswapV3 from "./images/UniswapV3.jpeg";
import MakerDAO_Dai from "./images/MakerDAO_Dai.jpeg";
import MakerDAO_Endgame from "./images/MakerDAO_Endgame.jpeg";
import Lido from "./images/Lido.jpeg";
import { Link } from "react-router-dom";
// import Search from "./search_icon.svg";

function Research() {
  //Search box click function
  const [isClicked, setIsClicked] = useState(false);
  const handleClick = () => {
    setIsClicked(true);
  };

  //Truncate function to limit research content body text
  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  return (
    <div className="research">
      <div className="research_wrapper">
        <div className="search_box" onClick={() => handleClick()}>
          {/* <Search /> */}
          {/* <img src={Search} alt="" /> */}
          <span>Search</span>
          {/* {isClicked && <input type="text" placeholder="Search" />} */}
        </div>
        <div className="container">
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  $frxETH: Frax's Pursuit of Conquering the Liquid Staking
                  Derative(LSD) Model
                </span>
                <span className="content_body">
                  {truncate(
                    "One of the newest players in the LSDs market is Frax. This DeFi protocol offers an array of products, including two stablecoins (FRAX + FPI), a decentralized exchange (Fraxswap), a lending protocol (Fraxlend), and a bridge (Fraxferry). With this Frax aims to provide a one-stop shop for DeFi investors, as it covers most of the DeFi primitives available today. The addition of LSDs to the Frax ecosystem further enhances this by providing investors with a way to earn staking rewards while remaining liquid and within the Frax ecosystem. Here is how their frxETH page looks with the rest of the Frax products on the left menu:",
                    180
                  )}
                </span>
                <span className="content_date">Apr 4 2023 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={Frax_frxEth} alt="Frax_frxEth" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  LIDO: The Realm of Liquid Staking Derivatives(LSDs)
                </span>
                <span className="content_body">
                  {truncate(
                    "Liquid staking is a fairly straightforward business. These protocols allow anyone in the world to become a validator of a proof-of-stake network with a few clicks of a mouse. For example, if you want to make your ETH useful and earn a yield, you can connect your wallet to a website like Lido Finance and deposit into their smart contracts. Current yields are 5% APR (paid in ETH). Unlike many yields in crypto, this one is legitimate. Your yield will consist of user transaction fees, MEV, tips, and protocol inflation.",
                    180
                  )}
                </span>
                <span className="content_date">Mar 29 2023 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={Lido} alt="Lido" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  MakerDAO: The Endgame Plan
                </span>
                <span className="content_body">
                  {truncate(
                    "The Endgame Plan is an ambitious initiative that aims to create a finite roadmap for MakerDAO that step by step leads to a predetermined, immutable end state many years out in the future, while significantly improving governance dynamics and tapping into the raw power of modern DeFi innovation.",
                    180
                  )}
                </span>
                <span className="content_date">Mar 22 2023 / Tun. L</span>
              </div>
            </Link>
            <img
              className="content_img"
              src={MakerDAO_Endgame}
              alt="MakerDAO_Endgame"
            />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  $DAI: MakerDAO's Collateralized Debt Position (CDP)
                </span>
                <span className="content_body">
                  {truncate(
                    "MakerDAO is a protocol behind the stable coin DAI — a cryptocurrency that maintains a 1:1 peg to the USD. Think of 1 DAI as $1. What makes it unique is each DAI is backed by Ether instead of a 3rd party claiming to have the required collateral. Since Ether is volatile this poses some interesting challenges to maintain the peg.",
                    180
                  )}
                </span>
                <span className="content_date">Mar 15 2023 / Tun. L</span>
              </div>
            </Link>
            <img
              className="content_img"
              src={MakerDAO_Dai}
              alt="MakerDAO_Dai"
            />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  $GHO: AAVE's Native Stablecoin
                </span>
                <span className="content_body">
                  {truncate(
                    "GHO is a decentralized stablecoin issued by Aave. GHO is overcollateralized as it’s backed by deposits in Aave V3, with users able to mint the stablecoin directly into the money market. GHO is unique in that, rather than having interest rates determined algorithmically like other assets in Aave, its borrow rate will be manually set by governance. This gives Aave total control over the cost to mint/borrow GHO, allowing the DAO to potentially undercut its competitors.",
                    180
                  )}
                </span>
                <span className="content_date">Mar 8 2023 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={AaveGho} alt="AaveGho" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  $crvUSD: Curve's Stablecoin Whitepaper
                </span>
                <span className="content_body">
                  {truncate(
                    "Curve, the second largest DEX in the world, has released the first public iteration of their new stablecoin whitepaper. $crvUSD is designed to work around a few novel concepts, three of which are highlighted in the whitepaper: lending-liquidating AMM algorithm — LLAMMA, the PegKeeper and the overall monetary policy.",
                    180
                  )}
                </span>
                <span className="content_date">Mar 1 2023 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={CrvUsd} alt="CrvUsd" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  Frax Price Index (FPI): Innovation of An Inflation-Linked
                  Stablecoin
                </span>
                <span className="content_body">
                  {truncate(
                    "FPI is unique in that it’s the first stablecoin to track the U.S Consumer Price Index (CPI). Using oracle data from Chainlink, each month will see a “dollar-denominated value” adjustment in line with the movement of inflation.",
                    180
                  )}
                </span>
                <span className="content_date">Feb 22 2023 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={Frax_FPI} alt="Frax_FPI" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  Frax Finance V2: Algorithmic Market Operations (AMO)
                </span>
                <span className="content_body">
                  {truncate(
                    "While Frax v1 introduced the concept of a fractional algorithmic stablecoin, Frax v2 introduces the building blocks, simple mechanisms that are used as value accrual vehicles for $FXS. AMO controller is intended not to lower the collateral ratio and change the $FRAX price by altering the supply.                  ",
                    180
                  )}
                </span>
                <span className="content_date">Feb 15 2023 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={FraxV2} alt="FraxV2" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  Frax Finance V1: A Semi-Algo Stablecoin Deep Dive
                </span>
                <span className="content_body">
                  {truncate(
                    "Frax is a stablecoin protocol that contains two main products; FRAX and FPI (Frax Price Index). FRAX is a fractional algorithmic stablecoin that is pegged to the US dollar, meaning it can be minted using an exogenous asset (USDC) and an endogenous asset (FXS) that will be burned. Frax Shares (FXS) is the non-stable governance token for the protocol. Holders of FXS are able to earn a share of protocol fees",
                    180
                  )}
                </span>
                <span className="content_date">Feb 8 2023 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={FraxV1} alt="FraxV1" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  The Curve Wars: DeFi's Clash for Liquidity & Power
                </span>
                <span className="content_body">
                  {truncate(
                    "The Curve war can be described as the race between various protocols that are continuously trying to ensure that their preferred pools are offering the highest $CRV rewards (‘bribes’). The Curve war is leading to increased buying pressure for $CRV.",
                    180
                  )}
                </span>
                <span className="content_date">Feb 1 2023 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={CurveWars} alt="CurveWars" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  Curve DAO: Understanding Vote-Escrowed Tokens (VeTokens)
                </span>
                <span className="content_body">
                  {truncate(
                    "Curve DAO token (CRV) is an Ethereum token that is powered by Curve finance. Which can be stated as the decentralised exchange and an automated market maker protocol. Curve DAO consists of multiple smart contracts connected to Aragon. And generally Aragoon 1 token = 1 vote however this voting method is replaced with the weighted voting method that will be discussed in this paper.",
                    180
                  )}
                </span>
                <span className="content_date">Jan 25 2023 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={CurveDAO} alt="CurveDAO" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  Curve V2: CryptoPools & Exposure to Volatility
                </span>
                <span className="content_body">
                  {truncate(
                    "Curve v2 pools are designed to provide deep liquidity for a broad range of assets with varying degrees of volatility. Because different trading pairs can exhibit drastically different price dynamics, Curve v2 offers a variety of tunable parameters that can be used to optimize for different types of assets (see Curve Factory; Non-Pegged Assets). Here, I will go over each of these parameters so that users can have a basic understanding of how they work.",
                    180
                  )}
                </span>
                <span className="content_date">Jan 18 2023 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={CurveV2} alt="CurveV2" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  Curve V1: The Stableswap Invariant
                </span>
                <span className="content_body">
                  {truncate(
                    "Understanding constant sum & product invariant. How stable swap invariant is built? Implications of stable swap invariant & Amplification coefficient. A stable swap invariant is used in the pools having assets having the same value i.e. pegged assets. The information is a bit math-heavy but I am sure you would be impressed learning the implications of the invariant used by Curve V1.",
                    180
                  )}
                </span>
                <span className="content_date">Jan 11 2023 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={CurveV1} alt="CurveV1" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  Aave V3: The Leap Towards Cross-Chain Interoperability
                </span>
                <span className="content_body">
                  {truncate(
                    "During V3, the team is proposing a more decentralized and more interoperable lending protocol. In this new protocol, AAVE V3 is enabled for cross-chain interoperability by its new feature called portal and prepare for open to all kinds of assets. More importantly, for the Defi community, AAVE V3 is pointing to a new era where the application is eating infrastructure. In the next shifting, giant applications (like AAVE) do not belong to any ecosystem, they’re interoperable and cross-chain.",
                    180
                  )}
                </span>
                <span className="content_date">Jan 4 2023 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={AaveV3} alt="AaveV3" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  Aave V2: Debt Tokenization & Loan Functionality Upgrades
                </span>
                <span className="content_body">
                  {truncate(
                    "During V3, the team is proposing a more decentralized and more interoperable lending protocol. In this new protocol, AAVE V3 is enabled for cross-chain interoperability by its new feature called portal and prepare for open to all kinds of assets. More importantly, for the Defi community, AAVE V3 is pointing to a new era where the application is eating infrastructure. In the next shifting, giant applications (like AAVE) do not belong to any ecosystem, they’re interoperable and cross-chain.",
                    180
                  )}
                </span>
                <span className="content_date">Dec 28 2022 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={AaveV2} alt="AaveV2" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  Aave V1: The King of DeFi Lending & Borrowing
                </span>
                <span className="content_body">
                  {truncate(
                    "During V3, the team is proposing a more decentralized and more interoperable lending protocol. In this new protocol, AAVE V3 is enabled for cross-chain interoperability by its new feature called portal and prepare for open to all kinds of assets. More importantly, for the Defi community, AAVE V3 is pointing to a new era where the application is eating infrastructure. In the next shifting, giant applications (like AAVE) do not belong to any ecosystem, they’re interoperable and cross-chain.",
                    180
                  )}
                </span>
                <span className="content_date">Dec 21 2022 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={AaveV1} alt="AaveV1" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  UniswapV3: The Concept of Concentrated Liquidity
                </span>
                <span className="content_body">
                  {truncate(
                    "Uniswap V1 & V2 allowed liquidity providers to supply liquidity for the whole price spectrum but what if you can provide liquidity in the specific price range? How will it change the things?",
                    180
                  )}
                </span>
                <span className="content_date">Dec 14 2022 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={UniswapV3} alt="UniswapV3" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  UniswapV2: Introduction of ERC20 Pair Trades & Flash Swaps
                </span>
                <span className="content_body">
                  {truncate(
                    "Uniswap allows anyone to make market and swap tokens. The first version — Uniswap V1, was released in 2018 as a proof of concept. Version 2 was a production version released in 2020. And Version 3, which is currenty the latest version, was launched in 2021. In this post, I will explain how Uniswap V2 works with examples that walk through basic scenarios, and how the simple but powerful formula works.",
                    180
                  )}
                </span>
                <span className="content_date">Dec 7 2022 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={UniswapV2} alt="UniswapV2" />
          </div>
          <div className="content">
            <Link className="research_content_link" to="/article2">
              <div className="content_info">
                <span className="content_header">
                  UniswapV1: The Birth of Automated Market Makers (AMMs)
                </span>
                <span className="content_body">
                  {truncate(
                    "Uniswap is a protocol on Ethereum for swapping ERC20 tokens. Unlike most exchanges, which are designed to take fees, Uniswap is designed to function as a public good—a tool for the community to trade tokens without platform fees or middlemen. Also unlike most exchanges, which match buyers and sellers to determine prices and execute trades, Uniswap uses a simple math equation and pools of tokens and ETH to do the same job.",
                    180
                  )}
                </span>
                <span className="content_date">Nov 30 2022 / Tun. L</span>
              </div>
            </Link>
            <img className="content_img" src={UniswapV1} alt="UniswapV1" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Research;
