import React from "react";
import "./About.css";
import Footer from "./Footer";
import MakerDAO_Endgame from "./images/MakerDAO_Endgame.jpeg";
import CrvUsd from "./images/Curve_crvusd.png";
import AaveV3 from "./images/AaveV3.jpeg";
import UniswapV3 from "./images/UniswapV3.jpeg";
import { Link } from "react-router-dom";

function About() {
  //Truncate function to limit research content body text
  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  return (
    <div className="home">
      <div className="wrapper">
        <div className="header">
          <h1>
            Lumen Research is an independent crypto research firm offering DeFi
            insights & in-depth White paper analyses.
          </h1>
        </div>
        <div className="body">
          <span>
            Cryptocurrency and DeFi (Decentralized Finance) are rapidly gaining
            momentum in both the technology and finance sectors. Although the
            innovative concepts of decentralized finance are still in their
            infancy, they hold immense potential for disrupting the financial
            landscape. We are a team of passionate builders and community
            members collaborating to make decentralized finance accessible to
            everyone. We break down complex mechanisms of the broader DeFi
            ecosystem and simplify technical white papers for the curious and
            the enthusiasts.
          </span>
        </div>
        <div className="research_container">
          <div className="research_header">
            <span className="research_title">Research</span>
            <Link className="research_seeMore" to="/research">
              See more &#62;&#62;
            </Link>
          </div>
          <div className="research_contents_container">
            <div className="research_content_left">
              <Link className="research_content_link" to="/article1">
                <div className="research_content_body">
                  <div className="research_content_info">
                    <span className="research_content_header">
                      MakerDAO: The Endgame Plan
                    </span>
                    <span className="research_content_body">
                      {truncate(
                        "The Endgame Plan is an ambitious initiative that aims to create a finite roadmap for MakerDAO that step by step leads to a predetermined, immutable end state many years out in the future, while significantly improving governance dynamics and tapping into the raw power of modern DeFi innovation.",
                        180
                      )}
                    </span>
                  </div>
                  <span className="research_content_date">
                    Jun 2023 / Tun. L
                  </span>
                </div>
              </Link>
              <img
                className="research_content_img"
                src={MakerDAO_Endgame}
                alt="MakerDAO_Endgame"
              />
            </div>
            <div className="research_content_right">
              <Link className="research_content_link" to="/article2">
                <div className="research_content_body">
                  <div className="research_content_info">
                    <span className="research_content_header">
                      $crvUSD: Curve's Stablecoin Whitepaper
                    </span>
                    <span className="research_content_body">
                      {truncate(
                        "Curve, the second largest DEX in the world, has released the first public iteration of their new stablecoin whitepaper. $crvUSD is designed to work around a few novel concepts, three of which are highlighted in the whitepaper: lending-liquidating AMM algorithm — LLAMMA, the PegKeeper and the overall monetary policy.",
                        180
                      )}
                    </span>
                  </div>
                  <span className="research_content_date">
                    Jun 2023 / Tun. L
                  </span>
                </div>
              </Link>
              <img className="research_content_img" src={CrvUsd} alt="CrvUsd" />
            </div>
          </div>
          <div className="research_contents_container">
            <div className="research_content_left">
              <Link className="research_content_link" to="/article3">
                <div className="research_content_body">
                  <div className="research_content_info">
                    <span className="research_content_header">
                      Aave V3: An Upgrade Towards Cross-Chain Interoperability
                    </span>
                    <span className="research_content_body">
                      {truncate(
                        "During V3, the team is proposing a more decentralized and more interoperable lending protocol. In this new protocol, AAVE V3 is enabled for cross-chain interoperability by its new feature called portal and prepare for open to all kinds of assets. More importantly, for the Defi community, AAVE V3 is pointing to a new era where the application is eating infrastructure. In the next shifting, giant applications (like AAVE) do not belong to any ecosystem, they’re interoperable and cross-chain.",
                        180
                      )}
                    </span>
                  </div>
                  <span className="research_content_date">
                    Jun 2023 / Tun. L
                  </span>
                </div>
              </Link>
              <img className="research_content_img" src={AaveV3} alt="AaveV3" />
            </div>
            <div className="research_content_right">
              <Link className="research_content_link" to="/article4">
                <div className="research_content_body">
                  <div className="research_content_info">
                    <span className="research_content_header">
                      UniswapV3: The Concept of Concentrated Liquidity
                    </span>
                    <span className="research_content_body">
                      {truncate(
                        "Uniswap V1 & V2 allowed liquidity providers to supply liquidity for the whole price spectrum but what if you can provide liquidity in the specific price range? How will it change the things?",
                        180
                      )}
                    </span>
                  </div>
                  <span className="research_content_date">
                    Jun 2023 / Tun. L
                  </span>
                </div>
              </Link>
              <img
                className="research_content_img"
                src={UniswapV3}
                alt="UniswapV3"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
