import "./App.css";
import About from "./About";
import Research from "./Research";
import Contact from "./Contact";
import Article1 from "./articles/MakerDAO/MakerDAO";
import Article2 from "./articles/crvUSD/crvUSD";
import Article3 from "./articles/AaveV3/AaveV3";
import Article4 from "./articles/UniswapV3/UniswapV3";
// import UniswapV1 from "./articles/UniswapV1/UniswapV1";
// import UniswapV2 from "./articles/UniswapV1/UniswapV2";
// import UniswapV3 from "./articles/UniswapV1/UniswapV3";
// import AaveV1 from "./articles/AaveV1/AaveV1";
// import AaveV2 from "./articles/AaveV2/AaveV2";
// import AaveV3 from "./articles/AaveV3/AaveV3";
// import CurveV1 from "./articles/CurveV1/CurveV1";
// import CurveV2 from "./articles/CurveV2/CurveV2";
// import CurveDAO from "./articles/CurveDAO/CurveDAO";
// import CurveWars from "./articles/CurveWars/CurveWars";
// import FraxV1 from "./articles/FraxV1/FraxV1";
// import FraxV2 from "./articles/FraxV2/FraxV2";
// import Frax_FPI from "./articles/Frax_FPI/Frax_FPI";
// import crvUSD from "./articles/crvUSD/crvUSD";
// import AaveGho from "./articles/AaveGho/AaveGho";
// import MakerDAO_Dai from "./articles/MakerDAO_Dai/MakerDAO_Dai";
// import MakerDAO_Endgame from "./articles/MakerDAO_Endgame/MakerDAO_Endgame";
// import Lido from "./articles/Lido/Lido";
// import Frax_frxEth from "./articles/Frax_frxEth/Frax_frxEth";
import ScrollToTop from "./ScrollToTop";

import Navbar from "./navbar_test";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<About />} />
        <Route path="/research" element={<Research />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/article1" element={<Article1 />} />
        <Route path="/article2" element={<Article2 />} />
        <Route path="/article3" element={<Article3 />} />
        <Route path="/article4" element={<Article4 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
