import React from "react";
import "./Contact.css";
import Footer from "./Footer";

function Contact() {
  return (
    <div className="contact">
      <div className="contact_wrapper">
        <div className="contact_container">
          <div className="contact_info">
            <span className="contact_header">Information</span>
            <a href="mailto:lumenmyanmar@gmail.com">
              <span className="contact_body">lumenmyanmar@gmail.com</span>
            </a>
          </div>
          <div className="contact_info">
            <span className="contact_header">Partnership</span>
            <a href="mailto:tunpyaysonelin@gmail.com">
              <span className="contact_body">tunpyaysonelin@gmail.com</span>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Contact;
