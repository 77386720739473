import React from "react";
import Footer from "../../Footer";
import "../Article.css";
import CrvUsd from "./Curve_crvusd.png";
import CrvUsd_LLAMMA from "./Curve_crvusd_LLAMMA.jpeg";
import CrvUsd_LLAMMA2 from "./Curve_crvusd_LLAMMA2.jpeg";
import CrvUsd_LLAMMA3 from "./Curve_crvusd_LLAMMA3.jpeg";
import CrvUsd_LLAMMA4 from "./Curve_crvusd_LLAMMA4.jpeg";

function Article() {
  return (
    <div className="article">
      <div className="article_wrapper">
        <div className="article_container">
          <span className="article_header">
            $crvUSD: Curve's Stablecoin Whitepaper
          </span>
          <span className="article_date">Mar 1 2023 / Tun. L</span>
          <img className="article_img" src={CrvUsd} alt="CrvUsd" />
          <span className="article_title">1. Introduction</span>
          <span className="article_body">
            Curve, the second largest DEX in the world, has released the first
            public iteration of their new stablecoin whitepaper. $crvUSD is
            designed to work around a few novel concepts, three of which are
            highlighted in the whitepaper: lending-liquidating AMM algorithm —
            LLAMMA, the PegKeeper and the overall monetary policy.
          </span>
          <span className="article_body">
            The main idea behind $crvUSD is to create a more sustainable
            stablecoin model with a smooth and gradual liquidation as opposed to
            the current end-all be-all model where liquidations happen in a
            relative instant. I will be going over the basics of $crvUSD, its
            model and the major concepts from the whitepaper as short and simply
            as possible.
          </span>
          <span className="article_body"></span>
          <span className="article_title">
            2. Lending-liquidating AMM algorithm — LLAMMA
          </span>
          <span className="article_body">
            Arguably the biggest risk that a borrower faces when participating
            in a money market is their collateral dropping below the liquidation
            price. As the vast majority of DeFi borrow/lend protocols function
            right now, whenever the liquidation threshold is reached, the
            collateral is liquidated in a single event. A bad borrower having to
            pay the lender is of course a good thing in itself. However, a big
            problem with decentralized lending markets is that the liquidation
            of a collateral could be higher than the liquidity available to
            actually liquidate.
          </span>
          <span className="article_body">
            This is why all lending protocols have massive participation in
            liquidity pools (LPs), on Curve, Uniswap, Balancer, etc. Great for
            accessing liquidity, however they are greatly affected in periods of
            extreme volatility or in the case of a bad actor looking for an
            exploit. Both Mango and Solend have had this issue under different
            forms in the recent past.
          </span>
          <img
            className="article_img"
            src={CrvUsd_LLAMMA}
            alt="CrvUsd_LLAMMA"
          />
          <span className="article_body">
            This is where LLAMMA swoops in. The AMM participation is
            internalized with the LP tokens themselves being the collateral
            token. Therefore, following the whitepaper’s example, in an ETH/USD
            pool, as the price of ETH goes down, the LP gradually sells ETH into
            USD. When price moves back up, the opposite happens, and USD is sold
            for ETH. If ETH never goes back up, the LP has enough USD at hand to
            guarantee the debt.
          </span>
          <span className="article_body">
            More so, the AMM itself is intended to operate through concentrated
            liquidity. This means that the liquidity itself is concentrated
            within a range. When the proportion of the LP moves too much to one
            side, collateral will be completely converted in ETH or USD. This is
            opposite to how AMMs work normally, where the LP holder suffers
            impermanent loss by always getting less of the appreciating asset
            and more of the depreciating asset.
          </span>
          <img
            className="article_img"
            src={CrvUsd_LLAMMA2}
            alt="CrvUsd_LLAMMA2"
          />
          <span className="article_body">
            However, once deposit proportion parity is reached again, the loss
            is dematerialized. For LLAMMA, the loss could be permanent as
            @DeFiCheetah very well notes, due to the fact that its liquidity
            moves solely within a range. This is one of the details yet to be
            clarified in the whitepaper. Impermanent loss for normal AMM is
            impermanent because if price level restores back to the level where
            LP deposited assets in the pool, there is no change in asset value.
            For LLAMMA, as the logic is reversed, this loss may be a permanent
            one instead. Details are not clear now.
          </span>
          <span className="article_body">
            If, however, this issue gets resolved, the model can be a game
            changer: Gradual liquidations imply a higher tolerance for risk,
            which in turn can mean more capital put into the market. More
            details will be put out in due time. At its base, it seems LLAMMA
            has taken inspiration from MakerDAO’s DAI stablecoin
            overcollateralized model.
          </span>
          <span className="article_body"></span>
          <span className="article_title">3. Peg Keeper</span>
          <span className="article_body">
            The PegKeeper is essentially an algorithmic market operations
            controller (AMO) that keeps $crvUSD value tied to $1USD. It does so
            through two actions:
          </span>
          <span className="article_body">
            1. If 1$crvUSD &#62; 1$USD, then the PegKeeper autonomous contract
            will mint uncollateralized stablecoin and deposit it into the
            stableswap pool. This increases supply, therefore causing the price
            to go down and regain the peg.
          </span>
          <span className="article_body">
            2. If 1$crvUSD &#60; 1$USD, then the PegKeeper will withdraw
            stablecoin and burn it. This decreases supply, thus increasing
            scarcity and causing the price to go up and regain the peg.
          </span>
          <img
            className="article_img"
            src={CrvUsd_LLAMMA3}
            alt="CrvUsd_LLAMMA3"
          />
          <span className="article_body">
            $crvUSD is quite algorithmic in nature, swapping between scarcity
            and abundance in order to keep the stablecoin…well…stable. This is
            not that foreign of a concept compared to OlympusDAO’s or TOMB’s
            tokenomics from last year. While those projects have a bunch of
            issues, I always argued there is merit behind this style of monetary
            policy and I think it’s great for crypto that a project like Curve
            is looking at fundamental math applications to make their best
            decisions as opposed to just doing what is popular.
          </span>
          <img
            className="article_img"
            src={CrvUsd_LLAMMA4}
            alt="CrvUsd_LLAMMA4"
          />
          <span className="article_title">
            4. Monetary Policy and implications
          </span>
          <span className="article_body">
            While there are still many details yet to be figured out and
            released publicly, it seems for now that $crvUSD will effectively be
            a somewhat algorithmic stablecoin with an internal AMM that creates
            an overcollateralized treasury that helps it keep its peg under
            volatile market conditions. The model is relatively similar to FRAX,
            however with some notable differences: Where $crvUSD will mint more
            stablecoin to decrease its scarcity, FRAX does so by taking out USDC
            from its Curve pool. FRAX also uses its treasury as the main tool to
            keep its peg, whereas $crvUSD controls its own supply.
          </span>
          <span className="article_body">
            The way that monetary policy works in this model represents a very
            interesting opportunity for LP participants: As $crvUSD is minted,
            users are charged a floating interest rate. Note, that even though
            the mint is uncollateralized, the stablecoin itself appears to be
            implicitly collateralized by the liquidity in the stablecoin pool.
            The system is at its most profitable when it is stable. Thus, when
            $crvUSD is &#62; $1, the interest rate decreases in order to
            incentivize users to borrow. This increases supply, thus price goes
            down back to peg. The opposite is the case if $crvUSD &#60; $1: The
            interest rate goes up, forcing lenders back and decreasing supply,
            thus regaining the peg. Welcome to the world of protocols being de
            facto Central Banks.
          </span>
          <span className="article_body">
            Curve really has the potential to break into new ground with its
            novel approach to stablecoin tokenomics. I sincerely believe that
            models such as Curve’s, FRAX, DAI or any other overcollateralized
            stablecoin, especially those heading towards decentralization, will
            do good not only to crypto, but eventually to the real world. A more
            fair economic system is desperately needed and I am pretty sure it
            will be born out of the degen brains of DeFi builders and apes.
          </span>
          <span className="article_body"></span>
          <span className="article_remarks">
            ---Follow us for more articles below!---
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Article;
